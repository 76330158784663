import React from 'react';
import {Card, Col, Container, Image, Row, Stack} from 'react-bootstrap';
import {FadeIn} from '@/components/animation';
import {useLocation} from 'react-router';
import {BrandSettings} from '@shared/responses';

interface State {
    brandSettings: BrandSettings;
    firstName: string;
}

export function Confirmation() {
    const location = useLocation();
    const {brandSettings, firstName} = location.state as State;

    return (
        <FadeIn>
            <Container>
                <Row>
                    <Col lg={{span: 6, offset: 3}}>
                        <Card className="shadow my-5">
                            <Card.Body>
                                <div className="h3 py-3 text-center">
                                    <Image src={brandSettings?.logoUrl}
                                        alt={brandSettings?.businessName}
                                        fluid
                                        style={{maxWidth: 100, maxHeight: 160}}
                                    />
                                </div>
                                <div className="h6 pb-3 text-center">
                                    {brandSettings?.businessName}
                                </div>
                                <div className="h3 pb-2 text-center text-success">
                                    Thanks!
                                </div>
                            </Card.Body>
                            <hr/>
                            <Card.Body>
                                <div className="h3 p-2 pb-4 text-center">
                                    {firstName}, you've successfully signed up!
                                </div>
                            </Card.Body>
                        </Card>

                        <Container className=" my-5">
                            <Card.Body>
                                <div className="h4 m-4 mb-3 text-center">
                                    Get Rewarded with the Spark Loyalty Mobile App
                                </div>
                                <div className="h4 m-0 pb-4 text-center">
                                    It's Free!
                                </div>

                                <Stack direction="horizontal" gap={3}>
                                    <a href="https://play.google.com/store/apps/details?id=io.snowshoe.sparktouch&pli=1"
                                        target="_blank"
                                        title="Google Play store"
                                        className="w-50 text-center" rel="noreferrer"
                                    >
                                        <Image src="https://static.getloyalti.com/app/google_play_badge.svg"
                                            className="w-75"
                                        />
                                    </a>

                                    <a href="https://apps.apple.com/zm/app/spark-touch/id1589827742"
                                        target="_blank"
                                        title="Google Play store"
                                        className="w-50 text-center" rel="noreferrer"
                                    >
                                        <Image src="https://static.getloyalti.com/app/apple_store_badge.svg"
                                            className="w-75"
                                        />
                                    </a>
                                </Stack>
                            </Card.Body>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </FadeIn>
    );
}
