import React from 'react';
import {useRouteError} from 'react-router-dom';
import {FadeIn} from '@/components/animation';
import {Card} from 'react-bootstrap';

export function ErrorBoundary() {
    let error = useRouteError() as Error;

    return (
        <FadeIn>
            <div className="m-5">
                <div className="container-sm">
                    <Card>
                        <Card.Body>
                            <div className="text-center p-5 text-muted">
                                <div className="pb-4">
                                    <i className="fa fa-fw fa-warning fa-3x"/>
                                </div>
                                <h3 className="pb-3">
                                    <div>Oops... looks like there was an issue.</div>
                                </h3>

                                <hr/>
                                <h6>Please try again.</h6>

                                {import.meta.env.DEV && (
                                    <>
                                        <h4>Debug:</h4>
                                        <div>{error.message}</div>
                                    </>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </FadeIn>
    );
}
