import React from 'react';
import {Spinner} from 'react-bootstrap';
import classNames from 'classnames';

interface Props {
    height?: number | string;
}

function CenteredSpinner({height}: Props) {
    const style = height ? {height} : null;
    const classes = height ? '' : 'min-vh-100';

    return (
        <div className={classNames('d-flex align-items-center justify-content-center text-center', classes)}
            style={style ?? {}}>
            <Spinner animation="grow"/>
        </div>
    );
}

export default CenteredSpinner;
