import {useMemo} from 'react';
import {Notify} from '@/services/notifications/Notify';
import {Notyf} from 'notyf';
import {logger} from 'sparkloyalty-frontend/logging';

const toaster = new Notyf({position: {x: 'center', y: 'bottom'}});

export function useNotify() {
    return useMemo((): Notify => {
        return ({
            success: (message: string) => {
                toaster.success(message);
            },
            errorMessage: (message: string) => {
                if ((message?.length ?? 0) === 0) {
                    message = 'Oops... it looks like something went wrong. Please try again.';
                }
                toaster.error(message);
            },
            error(error: Error, message?: string, context?: Record<string, unknown>) {
                this.errorMessage(message ?? error.message);
                if (error) {
                    logger.error(error, context);
                }
            },
        });
    }, []);
}
