import React, {PropsWithChildren, useMemo} from 'react';
import {getContrastYIQ} from '@/services/theme';
import {BrandSettings} from '@shared/responses';

interface Props {
    pageName?: string;
    brandSettings: BrandSettings;
}

export function PageHeader({pageName, brandSettings}: PropsWithChildren<Props>) {
    const headerTextColor = useMemo(() => {
        return brandSettings?.brandColor1 ? getContrastYIQ(brandSettings.brandColor1) : '#ffffff';
    }, [brandSettings?.brandColor1]);

    return (
        <>
            <div style={{
                height: '3em',
                backgroundColor: brandSettings?.brandColor1,
            }}/>

            <div className="h1 text-center m-0"
                style={{
                    backgroundColor: brandSettings?.brandColor1,
                    color: headerTextColor,
                }}
            >
                {pageName ?? brandSettings?.businessName}
            </div>

            <div style={{
                height: '9em',
                backgroundColor: brandSettings?.brandColor1,
            }}
            className="shadow"
            />
        </>
    );
}
