export const themeColors = {
    primary: '#6658EA',
    secondary: '#1B1642',
    success: '#2CD4A2',
    danger: '#F9655B',
    warning: '#F5C070',
    info: '#575A7B',
    light: '#ededf0',
    dark: '#212529',
};


export function getRgb(hexColor: string) {
    if (hexColor.length !== 4 && hexColor.length !== 7) {
        return {red: 0, green: 0, blue: 0};
    }

    const isLongCode = hexColor.length === 7;
    let hexRed = isLongCode ? hexColor.substring(1, 3) : hexColor.substring(1, 2);
    let hexGreen = isLongCode ? hexColor.substring(3, 5) : hexColor.substring(2, 3);
    let hexBlue = isLongCode ? hexColor.substring(5, 7) : hexColor.substring(3, 4);
    if (!isLongCode) {
        hexRed += hexRed;
        hexGreen += hexGreen;
        hexBlue += hexBlue;
    }

    const red = parseInt(hexRed, 16);
    const green = parseInt(hexGreen, 16);
    const blue = parseInt(hexBlue, 16);

    return {red, green, blue};
}

export function getContrastYIQ(hexColor: string) {
    const {red, green, blue} = getRgb(hexColor);

    const yiqColor = ((red * 299) + (green * 587) + (blue * 114)) / 1000;
    return yiqColor >= 128 ? 'black' : 'white';
}
