import axios, {AxiosResponse} from 'axios';
import {config} from '@/config';
import {BrandSettings, CampaignLandingPageSettings, Signup, SignUpPageSettings} from '@shared/responses';
import {isObject} from 'lodash';
import Notify from '@/services/notifications/Notify';
import {useMemo} from 'react';

export const axiosInterceptorsSetup = (navigate: (route: string) => void, notify: Notify) => {
    axios.interceptors.response.use(
        (response) => {
            if (isObject(response.data)) {
                /* react-bootstrap throws a warning when unset values are null,
                 * Preemptively transform them to undefined instead.
                 */
                Object.keys(response.data).forEach((key: string) => {
                    if (response.data[key] === null) {
                        response.data[key] = undefined;
                    }
                });
            }

            return response;
        },
        error => {
            if (error?.response?.status === 403) {
                navigate('/');
            }
            if (error?.response?.status === 404) {
                navigate('/not-found');
            }

            const hasValidationErrors = error?.response?.data?.validationMessages?.length > 0;
            if (!hasValidationErrors && (error?.response?.status === 400 || error?.response?.status >= 500)) {
                const errorMessage = error?.response?.data?.errorMessage
                    || error?.response?.data?.message
                    || error?.response?.statusText
                    || error.message;
                notify.error(error, errorMessage);
            }
            return Promise.reject(error);
        },
    );
};

export const makeQueryString = (params: any) => Object.keys(params || {})
    .filter((key) => params[key] !== '')
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export const makeAppUrl = (path: string, params: object) => {
    const baseUrl = config.makeAppUrl(path);
    const queryString = makeQueryString(params);
    return `${baseUrl}?${queryString}`;
};

const apiBasePath = `/api`;


export const configurationResource = {
    get: () => axios.get(`${apiBasePath}/configuration`),
};

export interface CustomerSignupResource {
    get: (hash: string) => Promise<AxiosResponse<BrandSettings>>;
    post: (hash: string, values: Signup) => Promise<AxiosResponse<Signup>>;
}

export function useCustomerSignupResource() {
    return useMemo(() => {
        const path = `${apiBasePath}/signup`;
        return ({
            get: (hash: string): Promise<AxiosResponse<SignUpPageSettings>> => {
                return axios.get(`${path}/${hash}`);
            },
            post: (hash: string, values: Signup): Promise<AxiosResponse<Signup>> => {
                return axios.post(`${path}/${hash}`, values);
            },
        }) as CustomerSignupResource;
    }, []);
}

export interface CampaignLandingPageResource {
    get: (hash: string) => Promise<AxiosResponse<BrandSettings>>;
}

export function useCampaignLandingPageResource() {
    return useMemo(() => {
        const path = `${apiBasePath}/campaign-landing-page`;
        return ({
            get: (hash: string): Promise<AxiosResponse<CampaignLandingPageSettings>> => {
                return axios.get(`${path}/${hash}`);
            },
        }) as CampaignLandingPageResource;
    }, []);
}
