import React, {useCallback, useContext, useEffect, useState, useMemo} from 'react';
import ageGateImage from '@images/plus21.svg';
import {
    Button,
    Card,
    Col,
    Container,
    Form as BsForm,
    FormCheck,
    FormControl,
    FormGroup,
    Image,
    Modal,
    Row,
    Stack
} from 'react-bootstrap';
import {CenteredSpinner, FadeIn} from '@/components/animation';
import {useLoaderData, useNavigate, useParams} from 'react-router';
import {CustomerSignupResource, useCustomerSignupResource} from '@/services/api';
import {Signup, SignUpPageSettings} from '@shared/responses';
import {makeSignupSchema} from '@shared/requests';
import {useFormError} from '@/services';
import {yupResolver} from '@hookform/resolvers/yup';

import {Controller, FieldValues, useForm} from 'react-hook-form';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import {PageHeader, Section} from '@/components/container';
import {AppContext} from '@/AppContext';
import {SelectOption} from '@shared';
import {Theme} from '@/components/whiteLabel';
import {Label} from '@/components/form/Label';
import {useSearchParams} from 'react-router-dom';
import {countryCodesISO} from "@shared/requests/signupSchema";
import signup from "@/pages/Signup/index";

export async function loader(hash: string, customerSignupResource: CustomerSignupResource) {
    const {data} = await customerSignupResource.get(hash);
    return data;
}


export function Form() {
    const [searchParams] = useSearchParams();
    const isPreview = searchParams.get('mode') === 'preview';
    const [isUnder21, setIsUnder21] = useState(false);

    const signupPageSettings = useLoaderData() as SignUpPageSettings;
    const businessCountry = signupPageSettings.country;
    const {
        formFieldDefinitions,
        isAgeGateRequired,
        showAddressSection,
        showPreferenceSection,
    } = signupPageSettings;

    const [showModal, setShowModal] = useState<boolean>(isAgeGateRequired);
    const [selectedCountry, setSelectedCountry] = useState<string>(businessCountry);

    const {fields} = useContext(AppContext);
    const {hash} = useParams<{ hash: string }>();
    const customerSignupResource = useCustomerSignupResource();
    const navigate = useNavigate();
    const validationSchema = makeSignupSchema(formFieldDefinitions);
    const resolver = yupResolver(validationSchema);

    const isUSAOrNoneSelected = useMemo(() => ['USA', 'Select...', ''].includes(selectedCountry), [selectedCountry]);


    const {
        register,
        handleSubmit,
        control,
        reset,
        setError,
        formState: {
            errors,
            isSubmitting,
        },
    } = useForm<Signup>({
        resolver,
    });
    const formError = useFormError(errors);
    const onSubmit = useCallback((values: FieldValues) => {
        if (!hash) {
            return;
        }
        return customerSignupResource.post(hash, values as Signup)
            .then(({data}) => {
                const state = {
                    brandSettings: signupPageSettings,
                    firstName: data.firstName,
                };
                navigate('/sign-up-confirmation', {state});
            })
            .catch(({response: {data: {validationMessages}}}) => {
                validationMessages.forEach((validationMessage: { paramName: string, message: string, }) => {
                    // @ts-ignore
                    setError(validationMessage.paramName, {
                        type: 'custom',
                        message: validationMessage.message,
                    });
                });
            });
    }, [hash, signupPageSettings, errors]);

    useEffect(() => {
        reset({
            mobileNumber: '',
            email: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            province: '',
            country: businessCountry,
            zipCode: '',
            textOptIn: false,
            emailOptIn: true,
        } as Signup);

        setShowModal(true);
    }, []);


    if (!signupPageSettings) {
        return <CenteredSpinner/>;
    }

    return (
        <FadeIn className="p-0" style={isPreview ? {pointerEvents: 'none'} : {}}>
            <Modal show={!isPreview && isAgeGateRequired && showModal} backdrop="static" keyboard={false} centered>
                <div>
                    <div className="mx-auto w-75">
                        <Image src={ageGateImage} alt="You must be over 21 years old" fluid/>
                    </div>
                    {isUnder21 ?
                        <div className="text-center pb-4">You must be 21+ to subscribe to messages from this
                            business.</div> :
                        <div className="text-center pb-4">
                            <div className="fs-5 mb-1 fw-bold">Are you older than 21 years?</div>
                            <div className="fs-6 my-1">You must be 21+ to subscribe to notifications for this
                                business.
                            </div>
                        </div>
                    }
                </div>
                <Modal.Footer className="d-flex justify-content-center">
                    {isUnder21 ?
                        <Button variant="primary"
                            className="px-4 w-100"
                            size="lg"
                            onClick={() => {
                                setIsUnder21(false);
                            }}
                        >
                            Okay
                        </Button> :
                        <Stack direction="vertical" gap={2}>
                            <Button variant="primary"
                                size="lg"
                                autoFocus={true}
                                className="px-4" onClick={() => setShowModal(false)}>
                                Yes, I'm over 21.
                            </Button>
                            <Button variant="outline-light"
                                className="text-primary"
                                onClick={() => setIsUnder21(true)}>
                                No
                            </Button>
                        </Stack>
                    }
                </Modal.Footer>
            </Modal>

            <Theme primaryColorHex={signupPageSettings.brandColor1}/>
            <PageHeader brandSettings={signupPageSettings}/>

            <Container style={{marginTop: '-10em'}}>
                <Row>
                    <Col lg={{span: 8, offset: 2}}>
                        <Card className="shadow my-5" style={{backgroundColor: '#fff', zIndex: '900'}}>
                            <Card.Body className="">
                                <div className="h3 p-0 m-0 pt-3 text-center">
                                    <Image src={signupPageSettings?.logoUrl}
                                        alt={signupPageSettings?.businessName}
                                        fluid
                                        style={{maxWidth: 100, maxHeight: 160}}
                                    />
                                </div>
                            </Card.Body>

                            <Card.Body className="mx-4">
                                <BsForm onSubmit={handleSubmit(onSubmit)}
                                    onReset={() => reset()}
                                    id="signup-form"
                                >
                                    <Section title="Profile">
                                        <Row>
                                            <FormGroup className="mb-3" controlId="firstName" as={Col} sm={6}>
                                                <Label isRequired={true}>First Name</Label>
                                                <Controller control={control}
                                                    name="firstName"
                                                    render={({field: {onChange, onBlur, value, ref}}) => (
                                                        <FormControl onChange={onChange}
                                                            value={value ?? ''}
                                                            ref={ref}
                                                            disabled={isSubmitting}
                                                            isInvalid={!!errors.firstName}
                                                            aria-required="true"
                                                        />)}/>
                                                {formError('firstName')}
                                            </FormGroup>

                                            {formFieldDefinitions.lastName &&
                                                <FormGroup className="mb-3" controlId="lastName" as={Col} sm={6}>
                                                    <Label isRequired={formFieldDefinitions.lastName.isRequired}>Last
                                                        Name</Label>
                                                    <Controller control={control}
                                                        name="lastName"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormControl onChange={onChange}
                                                                value={value ?? ''}
                                                                ref={ref}
                                                                isInvalid={!!errors.lastName}
                                                                disabled={isSubmitting}
                                                                aria-required="true"
                                                            />)}/>
                                                    {formError('lastName')}
                                                </FormGroup>
                                            }
                                        </Row>

                                        {formFieldDefinitions.email &&
                                            <Row>
                                                <FormGroup className="mb-3" controlId="email" as={Col} sm={12}>
                                                    <Label isRequired={formFieldDefinitions.email.isRequired}>
                                                        Email
                                                    </Label>
                                                    <Controller control={control}
                                                        name="email"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormControl onChange={onChange}
                                                                value={value ?? ''}
                                                                ref={ref}
                                                                disabled={isSubmitting}
                                                                isInvalid={!!errors.email}
                                                                aria-required="true"
                                                            />)}/>
                                                    {formError('email')}
                                                </FormGroup>
                                            </Row>
                                        }

                                        <Row className="justify-content-between">
                                            {formFieldDefinitions.mobileNumber &&
                                                <FormGroup className="mb-3" controlId="mobileNumber" as={Col}
                                                    sm={6}>
                                                    <Label isRequired={true}>Mobile Number</Label>
                                                    <PhoneInput
                                                        country = {useMemo(() => countryCodesISO[selectedCountry], [selectedCountry])}
                                                        name="mobileNumber"
                                                        className="form-control"
                                                        control={control}
                                                        inputComponent={FormControl}
                                                        isInvalid={!!errors.mobileNumber}
                                                        disabled={isSubmitting}
                                                        rules={{required: true}}
                                                        aria-required="true"
                                                    />
                                                    {formError('mobileNumber')}
                                                </FormGroup>
                                            }

                                            {formFieldDefinitions.dateOfBirth &&
                                                <FormGroup controlId="dateOfBirth" as={Col} sm={6}>
                                                    <Label isRequired={formFieldDefinitions.dateOfBirth.isRequired}>
                                                        Birthday
                                                    </Label>
                                                    <Controller control={control}
                                                        name="dateOfBirth"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormControl onChange={onChange}
                                                                type="date"
                                                                ref={ref}
                                                                isInvalid={!!errors.dateOfBirth}
                                                                disabled={isSubmitting}
                                                                aria-required="true"
                                                            />)}/>
                                                    {formError('dateOfBirth')}
                                                </FormGroup>
                                            }
                                        </Row>
                                    </Section>

                                    <Section title="Address" isEnabled={showAddressSection}>
                                        {formFieldDefinitions.addressLine1 &&
                                            <Row>
                                                <FormGroup className="mb-3" controlId="addressLine1" as={Col}
                                                    sm={12}>
                                                    <Label
                                                        isRequired={formFieldDefinitions.addressLine1.isRequired}>Address
                                                        Line 1</Label>
                                                    <Controller control={control}
                                                        name="addressLine1"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormControl onChange={onChange}
                                                                value={value ?? ''}
                                                                ref={ref}
                                                                isInvalid={!!errors.addressLine1}
                                                                disabled={isSubmitting}
                                                                aria-required="true"
                                                            />)}/>
                                                    {formError('addressLine1')}
                                                </FormGroup>
                                            </Row>
                                        }

                                        {formFieldDefinitions.addressLine2 &&
                                            <Row>
                                                <FormGroup className="mb-3" controlId="addressLine2" as={Col}
                                                    sm={12}>
                                                    <Label
                                                        isRequired={formFieldDefinitions.addressLine2.isRequired}>Address
                                                        Line 2</Label>
                                                    <Controller control={control}
                                                        name="addressLine2"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormControl onChange={onChange}
                                                                value={value ?? ''}
                                                                ref={ref}
                                                                isInvalid={!!errors.addressLine2}
                                                                disabled={isSubmitting}
                                                            />)}/>
                                                    {formError('addressLine2')}
                                                </FormGroup>
                                            </Row>
                                        }


                                        <Row>
                                            {formFieldDefinitions.city &&
                                                <FormGroup className="mb-3" controlId="city" as={Col} sm={6}>
                                                    <Label
                                                        isRequired={formFieldDefinitions.city.isRequired}>City</Label>
                                                    <Controller control={control}
                                                        name="city"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormControl onChange={onChange}
                                                                value={value ?? ''}
                                                                ref={ref}
                                                                isInvalid={!!errors.city}
                                                                disabled={isSubmitting}
                                                                aria-required="true"
                                                            />)}/>
                                                    {formError('city')}
                                                </FormGroup>
                                            }

                                            {formFieldDefinitions.state && isUSAOrNoneSelected &&
                                                <FormGroup className="mb-3" controlId="state" as={Col} sm={6}>
                                                    <Label
                                                        isRequired={formFieldDefinitions.state.isRequired}>State</Label>
                                                    <Controller control={control}
                                                        name="state"
                                                        render={({field}) => (
                                                            <BsForm.Select {...field}
                                                                isInvalid={!!errors.state}
                                                                disabled={isSubmitting}
                                                                name="state"
                                                                aria-label="Select a state"
                                                                aria-required="true"
                                                            >
                                                                <option>Select...</option>
                                                                {fields.usStates.items.map((option: SelectOption) => (
                                                                    <option value={option.value}
                                                                        key={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </BsForm.Select>
                                                        )}
                                                    />
                                                    {formError('state')}
                                                </FormGroup>
                                            }

                                            {formFieldDefinitions.province && !isUSAOrNoneSelected &&
                                                <FormGroup className="mb-3" controlId="province" as={Col} sm={6}>
                                                    <Label
                                                        isRequired={formFieldDefinitions.province.isRequired}
                                                    >Province/Region</Label>
                                                    <Controller control={control}
                                                        name="province"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormControl onChange={onChange}
                                                                value={value ?? ''}
                                                                ref={ref}
                                                                isInvalid={!!errors.province}
                                                                disabled={isSubmitting}
                                                                aria-required="true"
                                                            />
                                                        )}
                                                    />
                                                    {formError('province')}
                                                </FormGroup>
                                            }
                                        </Row>

                                        <Row>
                                            {formFieldDefinitions.country &&
                                                <FormGroup className="mb-3" controlId="country" as={Col} sm={6}>
                                                    <Label
                                                        isRequired={formFieldDefinitions.country.isRequired}>Country</Label>
                                                    <Controller control={control}
                                                        name="country"
                                                        render={({field}) => (
                                                            <BsForm.Select {...field}
                                                                onChange={event => {
                                                                    field.onChange(event);
                                                                    setSelectedCountry(event.target.value)
                                                                }}
                                                                isInvalid={!!errors.country}
                                                                disabled={isSubmitting}
                                                                name="country"
                                                                aria-label="Select a country"
                                                                aria-required="true"
                                                            >
                                                                <option>Select...</option>
                                                                {fields.countries.items.map((option: SelectOption) => (
                                                                    <option value={option.value}
                                                                        key={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </BsForm.Select>
                                                        )}
                                                    />
                                                    {formError('country')}
                                                </FormGroup>
                                            }

                                            {formFieldDefinitions.zipCode &&
                                                <FormGroup className="mb-3" controlId="zipCode" as={Col} sm={4}>
                                                    <Label
                                                        isRequired={formFieldDefinitions.zipCode.isRequired}>Postal/Zip</Label>
                                                    <Controller control={control}
                                                        name="zipCode"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormControl onChange={onChange}
                                                                value={value ?? ''}
                                                                ref={ref}
                                                                isInvalid={!!errors.zipCode}
                                                                disabled={isSubmitting}
                                                                aria-required="true"
                                                            />)}
                                                    />
                                                    {formError('zipCode')}
                                                </FormGroup>
                                            }
                                        </Row>
                                    </Section>

                                    <Section title="Preferences" isEnabled={showPreferenceSection}>
                                        <Row className="mt-1">
                                            {formFieldDefinitions.emailOptIn &&
                                                <FormGroup className="mb-3 ms-3" controlId="emailOptIn" as={Col}
                                                    sm={12}>
                                                    <Controller control={control}
                                                        name="emailOptIn"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormCheck
                                                                type="checkbox"
                                                                label="I would like to receive special offers via email."
                                                                onChange={onChange}
                                                                defaultChecked={true}
                                                                ref={ref}
                                                                isInvalid={!!errors.emailOptIn}
                                                                disabled={isSubmitting}
                                                            />
                                                        )}/>
                                                    {formError('emailOptIn')}
                                                </FormGroup>
                                            }
                                            {formFieldDefinitions.textOptIn &&
                                                <FormGroup className="mb-3 ms-3" controlId="textOptIn" as={Col}
                                                    sm={12}>
                                                    <Controller control={control}
                                                        name="textOptIn"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                ref
                                                            }
                                                        }) => (
                                                            <FormCheck
                                                                type="checkbox"
                                                                label="I would like to receive special offers via text message."
                                                                onChange={onChange}
                                                                defaultChecked={false}
                                                                ref={ref}
                                                                isInvalid={!!errors.textOptIn}
                                                                disabled={isSubmitting}
                                                            />
                                                        )}/>
                                                    {formError('textOptIn')}
                                                </FormGroup>
                                            }
                                        </Row>
                                    </Section>
                                </BsForm>
                            </Card.Body>
                            <Card.Footer>
                                <div className="d-grid gap-2 py-3 px-4">
                                    <Button type="submit"
                                        variant="outline-dark"
                                        form="signup-form"
                                        size="lg"
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </div>

                                <div className="pt-3 pb-5 text-center">
                                    By submitting this form, you agree to these <a
                                        href="https://sparkloyalty.net/terms-of-service-merchants" target="_blank"
                                        rel="noreferrer">Terms of
                                    Service</a>.
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>

                <div className="text-center pb-5">
                    <strong>25 million trusted check-ins at 1,000s of retailers.</strong>
                </div>
            </Container>

        </FadeIn>
    );
}
