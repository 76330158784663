import React, {createContext, PropsWithChildren, useMemo, useState} from 'react';
import {Configuration} from '@shared/responses';
import {SelectOptions} from '@shared';

interface Constants {
    unknownValue: string;
    campaignImageMaxFileSizeInBytes: number;
}

interface Context {
    constants: Constants;
    imageUrls: {
        defaultRewardImage: string
    },
    fields: {
        countries: {
            items: SelectOptions,
        },
        usStates: {
            items: SelectOptions,
        },
        dateRange: {
            map: {
                today?: number,
                thisWeek?: number,
                thisMonth?: number,
                thisYear?: number,
                custom?: number,
            },
        },
    };
}

export const AppContext = createContext<Context>({
    constants: {} as Constants,
    imageUrls: {
        defaultRewardImage: '',
    },
    fields: {
        countries: {
            items: [],
        },
        usStates: {
            items: [],
        },
        dateRange: {
            map: {},
        },
    },
});

interface Props {
    configuration: Configuration;
}

export default ({configuration, children}: PropsWithChildren<Props>) => {
    // Get initial context state from local storage.
    const constants = useMemo(() => ({
        unknownValue: '-',
    }), []);
    const [imageUrls] = useState({
        defaultRewardImage: configuration.imageUrls.defaultRewardImage,
    });

    const [fields] = useState(configuration.fields);

    const store = {
        constants,
        imageUrls,
        fields,
    };

    // @ts-ignore
    return (<AppContext.Provider value={store}>{children}</AppContext.Provider>);
};
