import {createRoot} from 'react-dom/client';
import {initConfig} from '@/config';
import {configurationResource} from '@/services/api';
import {initLogging} from 'sparkloyalty-frontend/logging';
import {makeAppContainer} from '@/AppContainer';
import {Configuration} from '@shared/responses';

configurationResource.get()
    .then((response) => {
        const configuration = response.data as Configuration;
        initConfig(configuration);
        initLogging(configuration.environment, configuration.isSentryEnabled);
        const container = document.getElementById('pages-app');
        const root = createRoot(container!);
        root.render(makeAppContainer(configuration));
    })
    .catch((error) => {
        console.log('Unable to get configuration', error);
    });
