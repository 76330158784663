import React from 'react';
import {Card, Col, Container, Image, Row,} from 'react-bootstrap';
import {CampaignLandingPageSettings} from '@shared/responses';
import {CenteredSpinner, FadeIn} from '@/components/animation';
import {useLoaderData} from 'react-router';
import {CampaignLandingPageResource} from '@/services/api';
import 'react-phone-number-input/style.css';
import {Theme} from '@/components/whiteLabel';
import {PageHeader} from '@/components/container';

export async function loader(hash: string, api: CampaignLandingPageResource) {
    const {data} = await api.get(hash);
    return data;
}

export function Page() {
    const campaignLandingPageSettings = useLoaderData() as CampaignLandingPageSettings;

    if (!campaignLandingPageSettings) {
        return <CenteredSpinner/>;
    }

    return (
        <FadeIn>
            <Theme primaryColorHex={campaignLandingPageSettings.brandColor1}/>
            <PageHeader brandSettings={campaignLandingPageSettings}/>

            <Container style={{marginTop: '-10em'}}>
                <Row>
                    <Col lg={{span: 8, offset: 2}}>
                        <Card className="shadow my-5" style={{backgroundColor: '#fff', zIndex: '900'}}>
                            <Card.Body className="">
                                <div className="h3 p-0 m-0 pt-3 text-center">
                                    <Image src={campaignLandingPageSettings?.logoUrl}
                                        alt={campaignLandingPageSettings?.businessName}
                                        fluid
                                        style={{maxWidth: 100, maxHeight: 160}}
                                    />
                                </div>
                            </Card.Body>

                            <Card.Body className="mx-4">
                                {campaignLandingPageSettings.content}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <div className="text-center pb-5">
                    <strong>25 million trusted check-ins at 1,000s of retailers.</strong>
                </div>
            </Container>
        </FadeIn>
    );
}
