import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {useNotify} from '@/services';
import {axiosInterceptorsSetup} from '@/services/api';

function Layout() {
    const [isInitialized, setIsInitialized] = useState(false);
    const navigate = useNavigate();
    const notify = useNotify();
    if (!isInitialized) {
        axiosInterceptorsSetup(navigate, notify);
        setIsInitialized(true);
    }

    return (
        <>
            <Outlet/>
        </>
    );
}

export default Layout;
