import React, {Suspense} from 'react';
import {CenteredSpinner, FadeIn} from '@/components/animation';
import AppContext from '@/AppContext';
import App from '@/App';

interface Props {
    configuration: any;
}

function AppContainer({configuration}: Props) {
    return (
        <div>
            <Suspense fallback={<CenteredSpinner/>}>
                <FadeIn>
                    <AppContext configuration={configuration}>
                        <App/>
                    </AppContext>
                </FadeIn>
            </Suspense>
        </div>
    );
}

function makeAppContainer(configuration: any) {
    return <AppContainer configuration={configuration}/>;
}

export {
    makeAppContainer,
};
