import React, {PropsWithChildren} from 'react';
import {FormLabel} from 'react-bootstrap';

interface Props {
    isRequired: boolean;
}

export function Label({isRequired, children}: PropsWithChildren<Props>) {
    return <FormLabel>{children}{isRequired ? '*' : ''}</FormLabel>;
}
