import * as yup from 'yup';
import {isPossiblePhoneNumber, parsePhoneNumberWithError, isValidPhoneNumber, CountryCode} from 'libphonenumber-js';
import {validateInternationalZipCode} from '../regularExpressions';

export interface CountryCodes {
    [key: string]: CountryCode;
    CAN: CountryCode;
    FIN: CountryCode;
    GBR: CountryCode;
    IRL: CountryCode;
    USA: CountryCode;
    PRI: CountryCode;
}
export const countryCodesISO : CountryCodes = {
    CAN: 'CA',
    FIN: 'FI',
    GBR: 'GB',
    IRL: 'IE',
    USA: 'US',
    PRI: 'PR',
}


const schema = {
    firstName: yup.string()
        .max(100, 'Should be 100 characters or fewer.'),
    lastName: yup.string()
        .max(100, 'Should be 100 characters or fewer.'),

    email: yup.string()
        .email('Must be a valid email address.')
        .max(255, 'Email should be 255 characters or fewer.')
        .nullable(),
    mobileNumber: yup.string()
        .nullable()
        .when('country', (country, schema)=> {
            return schema.test('isValidPhoneNumber', 'Must be a valid phone number.', (value) => {
                if(value[0]==='+') {
                    value = value.slice(1);
                }
                const countryCode = countryCodesISO[country];
                return isPossiblePhoneNumber(value ?? '', countryCode);
            });
        }),
    dateOfBirth: yup.string(),
    addressLine1: yup.string()
        .max(255, 'Street address should be 255 characters or fewer.'),
    addressLine2: yup.string()
        .max(255, 'Address line 2 should be 255 characters or fewer.'),
    city: yup.string()
        .max(255, 'City should be 255 characters or fewer.'),
    state: yup.string()
        .max(255, 'State should be 255 characters or fewer.')
        .when('country', {
            is: 'USA',
            otherwise: (schema) => schema.notRequired(),
        }),
    province: yup.string()
        .max(255, 'Province/Region name should be 255 characters or fewer.')
        .when('country', {
            is: 'USA',
            then: (schema) => schema.notRequired(),
        }),
    country: yup.string()
        .max(255, 'Country should be 255 characters or fewer.'),
    zipCode: yup.string()
        .test(
            'isZipCodeEmptyOrMatchesRegex',
            `Must be a valid zip code`,
            (zipCode: string, context) => {
                if (!zipCode) {
                    // The zip code is empty, so we don't want to check validity and exit early.
                    return true;
                }

                const country = context.parent.country;
                if (!country) {
                    // There is no country to use to select a regex for.
                    return true;
                }

                const regex = validateInternationalZipCode[country];
                if (!regex) {
                    // Somehow a country has been submitted that we don't have a regex for.
                    return false;
                }

                return zipCode.match(regex) !== null
            }
        ),

    emailOptIn: yup.boolean(),
    textOptIn: yup.boolean(),
};

export function makeSignupSchema(formFields: Record<string, { isRequired: boolean }>) {
    const definition = {};
    Object.keys(formFields).forEach(((fieldName) => {
        if (formFields[fieldName]?.isRequired && schema[fieldName]) {
            definition[fieldName] = schema[fieldName].required('Required.');
        } else {
            definition[fieldName] = schema[fieldName];
        }
    }));
    return yup.object().shape(definition);
}
