import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    LoaderFunctionArgs,
    Route,
    RouterProvider,
} from 'react-router-dom';
import NotFound from '@/pages/NotFound';
import Layout from '@/components/layout';
import {useCampaignLandingPageResource, useCustomerSignupResource} from '@/services/api';
import Signup from '@/pages/Signup';
import CampaignLandingPage from '@/pages/CampaignLandingPage';
import {ErrorBoundary} from '@/components/container';

function App() {
    const customerSignupResource = useCustomerSignupResource();
    const campaignLandingPageResource = useCampaignLandingPageResource();

    function checkHash(args: LoaderFunctionArgs): string {
        const {params: {hash}} = args;
        if (!hash) {
            throw new Error('Not found.');
        }
        return hash;
    }

    const router = createBrowserRouter(createRoutesFromElements(
        <>
            <Route path="/" element={<Layout/>}>
                <Route path="sign-up-confirmation" element={<Signup.Confirmation/>}/>
                <Route path="sign-up/:hash"
                    element={<Signup.Form/>}
                    hasErrorBoundary={true}
                    errorElement={<ErrorBoundary/>}
                    loader={async (args: LoaderFunctionArgs) => {
                        const hash = checkHash(args);
                        return await Signup.loader(hash, customerSignupResource);
                    }}
                />
                <Route path="l/:hash"
                    element={<CampaignLandingPage.Page/>}
                    hasErrorBoundary={true}
                    errorElement={<ErrorBoundary/>}
                    loader={async (args: LoaderFunctionArgs) => {
                        const hash = checkHash(args);
                        return await CampaignLandingPage.loader(hash, campaignLandingPageResource);
                    }}
                />
                <Route path="*" element={<NotFound/>}/>
            </Route>
            <Route path="/*" element={<NotFound/>}/>
        </>
    ));

    return (
        <>
            <RouterProvider router={router}/>
        </>
    );
}

export default App;
