import React, {useCallback} from 'react';
import Feedback from 'react-bootstrap/Feedback';

export function useFormError(errors: Record<string, unknown> | any) {
    return useCallback((fieldName: string) => {
        return <Feedback type="invalid">
            {errors[fieldName]?.message}
        </Feedback>;
    }, [errors]);
}
