import React, {useMemo} from 'react';
import {getRgb} from '@/services/theme';

interface Props {
    primaryColorHex: string;
}

export function Theme({primaryColorHex}: Props) {
    const primaryColorRgb = useMemo(() => {
        return getRgb(primaryColorHex ? primaryColorHex : '#6658EA');
    }, [primaryColorHex]);

    return (
        <>
            <style type="text/css">
                {`
                    .form-control:focus,
                    .form-select:focus {
                        border-color: ${primaryColorHex};
                        box-shadow: 0 0 0 0.25rem rgba(${primaryColorRgb.red}, ${primaryColorRgb.green}, ${primaryColorRgb.blue}, 0.25);
                    }

                    .form-check-input:checked {
                        background-color: ${primaryColorHex};
                        border-color: ${primaryColorHex};
                    }

                    .form-check-input:focus {
                        box-shadow: 0 0 0 0.25rem rgba(${primaryColorRgb.red}, ${primaryColorRgb.green}, ${primaryColorRgb.blue}, 0.25);
                    }
                `}
            </style>
        </>
    );
}
