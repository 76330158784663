import React, {CSSProperties, PropsWithChildren} from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
    style?: CSSProperties,
}

function FadeIn({
    className,
    style = {},
    children,
}: PropsWithChildren<Props>) {
    return (
        <div className={classNames('animate__animated animate__fadeIn animate__faster', className)} style={style}>
            {children}
        </div>
    );
}

export default FadeIn;
