export const validateZipCode = /^\s*?\d{5}(?:[-\s]\d{4})?\s*?$/;

export const validateInternationalZipCode = {
    'CAN': /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/,
    'FIN': /\d{5}/,
    'GBR': /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/,
    'IRL': /^(?:[ACDEFHKNPRTVWXY][0-9]{2}|D6W) [ACDEFHKNPRTVWXY0-9]{4}$/,
    'USA': /^\s*?\d{5}(?:[-\s]\d{4})?\s*?$/,
    'PRI': /^00[679]\d{2}([-\s]\d{4})?$/,
};
