import React, {PropsWithChildren} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

interface Props {
    title: string;
    description?: string;
    isEnabled?: boolean;
}

export function Section({
    title,
    description,
    children,
    isEnabled = true,
}: PropsWithChildren<Props>) {
    if (!isEnabled) {
        return null;
    }
    return (
        <fieldset className="border-top pt-3 mb-0" style={{borderRadius: 0}}>
            <Container fluid>
                <Row>
                    <Col md={4}>
                        <div className="h5 mb-3 mt-2">{title}</div>
                        {!!description && <div className="text-muted mb-3">{description}</div>}
                    </Col>
                    <Col md={8}>
                        {children}
                    </Col>
                </Row>
            </Container>
        </fieldset>
    );
}
